<template>
  <div class="d-flex justify-content-between">
    <span
      v-tooltip.top="
        editableItem.statutDoublon != null
          ? editableItem.statutDoublon
          : 'Aucun contenu. Cliquez pour éditer le contenu.'
      "
      @click.prevent.stop="
        showEditorPopup($event, '_motif_editor_' + editableItem.id, editableItem)
      "
      class="d-flexjustify-content- text-ellipsis w-100"
      style=""
    >
      <span v-if="editableItem.statutDoublon == null">N/A</span>
      <PBadge
        :value="editableItem.statutDoublon"
        :severity="editableItem.statutDoublon == 'ACCEPTE' ? 'primary' : 'danger'"
        v-else
      >
        {{ editableItem.statutDoublon }}
      </PBadge>
    </span>
    <ConfirmPopup style="z-index: 10000" :group="'_motif_editor_' + editableItem.id">
      <template #message>
        <div class="card shadow-0 border-0 p-3 mb-2">
          <div class="card-header">
            <h6>Décision de classification doublon</h6>
          </div>
          <div class="card-body">
            <div
              class="form-check"
              :key="index"
              v-for="(opt, index) in statutDoublonOptions"
            >
              <input
                class="form-check-input cursor-pointer"
                type="radio"
                :name="`_${editableItem.id}_${opt.value}`"
                :id="opt.value"
                :value="opt.value"
                v-model="editableItem.statutDoublon"
              />
              <label
                class="form-check-label cursor-pointer"
                :for="`_${editableItem.id}_${opt.value}`"
              >
                {{ opt.label }}
              </label>
            </div>
          </div>
        </div>
      </template>
    </ConfirmPopup>
    <PButton
      class="p-button-text p-button-sm"
      v-tooltip.left="'Editer le contenu'"
      icon="bi bi-pen"
      @click.prevent.stop="
        showEditorPopup($event, '_motif_editor_' + editableItem.id, editableItem)
      "
    ></PButton>
  </div>
</template>

<script>
import ConfirmPopup from "primevue/confirmpopup";
import { mapActions } from "vuex";
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    ConfirmPopup,
  },
  data() {
    return {
      hover: false,
      editableItem: {
        ...this.value,
      },
      statutDoublonOptions: [
        // { label: null, value: "Aucun" },
        { label: "ACCEPTE", value: "ACCEPTE" },
        { label: "ISOLE", value: "ISOLE" },
      ],
    };
  },
  methods: {
    ...mapActions({
      applyDoublonVerdict: "demandeur/applyDoublonVerdict",
    }),
    saveSstatutDoublon(item) {
      this.applyDoublonVerdict({
        ...item,
      }).then(() => {
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
      });
    },
    showEditorPopup(event, popupGroupName, item) {
      this.$confirm.require({
        group: popupGroupName,
        target: event.currentTarget,
        icon: "p",
        acceptLabel: "Valider",
        rejectLabel: "Fermer",
        accept: () => {
          this.saveSstatutDoublon(item);
        },
        reject: () => {},
      });
    },
  },
};
</script>
